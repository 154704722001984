import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getScanDetailsRequest, getScansRequest } from 'services/scan'
import { RootState } from 'store'

export const initialState: ScanState = {
	scans: [],
	activeScan: null,
	isLoading: true,
	errors: [],
}

export const getScans = createAsyncThunk<Scan[]>('scan/list', async (_, { dispatch }) => {
	dispatch(setIsLoading(true))

	return getScansRequest()
		.then((res: ListScanResponse) => {
			let scanList: Scan[] = []
			const scans = res.data
			let index = 0
			for (const key in scans) {
				const scan_with_domains = scans[key].map((scan: Scan) => {
					return { ...scan, ID: index++, domain: key }
				})
				scanList = [...scanList, ...scan_with_domains]
			}

			dispatch(setScans(scanList))
			return scanList
		})
		.catch((err: any) => {
			dispatch(setError([err.response.data.message]))
		})
		.finally(() => dispatch(setIsLoading(false))) as Promise<Scan[]>
})

export const getScanDetails = createAsyncThunk<any, string>('scan/details', async ({ domain, id }: any, { dispatch }) => {
	dispatch(setIsLoading(true))

	return getScanDetailsRequest(domain, id)
		.then((res: ViewScanResponse) => {
			dispatch(setActiveScan(res.data))
			return res.data
		})
		.catch((err: any) => {
			dispatch(setError([err.response.data.message]))
		})
		.finally(() => dispatch(setIsLoading(false))) as Promise<any>
})

export const scanSlice = createSlice({
	name: 'scan',
	initialState,
	reducers: {
		setScans: (state, { payload }: PayloadAction<Scan[]>) => {
			state.scans = payload
		},
		setActiveScan: (state, { payload }: PayloadAction<ScanDetail>) => {
			state.activeScan = {
				...payload,
				...{
					user_info: {
						userInfo: {
							gender: payload?.user_info?.userInfo?.gender,
							height: payload?.user_info?.userInfo?.height + 'cm',
							weight: payload?.user_info?.userInfo?.weight + 'kg',
						},
					},
				},
			}
		},
		setError: (state, { payload }: PayloadAction<any>) => {
			state.errors = payload
		},
		setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isLoading = payload
		},
	},
})

export const { setScans, setIsLoading, setError, setActiveScan } = scanSlice.actions

export const scanSelector = (state: RootState) => state.scan

export default scanSlice.reducer
