/*
 * Author: dizhong zhu
 * Date: 17/03/2023
 */

import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { DeleteAccount } from './deleteAccount'
import { useNavigate } from 'react-router-dom'
import { SIGN_IN } from '../../../common/routePaths'
import { loadAccount } from '../../loadUserData'

function UserSettings() {
	const [showDelete, setShowDelete] = useState(false)
	const [accountInfo, setAccountInfo] = useState({
		email: '',
		firstName: '',
		lastName: '',
	})
	const navigate = useNavigate()

	useEffect(() => {
		const fetchData = async () => {
			const account = await loadAccount()
			setAccountInfo({
				email: account.email,
				firstName: account.first_name,
				lastName: account.last_name,
			})
		}

		fetchData().then()
	}, [])

	const BeforeDeleteAccount = async () => {
		setShowDelete(true)
	}
	const AfterDeleteAccount = async (success) => {
		setShowDelete(false)
		if (success) {
			navigate(`${SIGN_IN}`)
		}
	}

	return (
		<Container>
			<Row>
				<Col>
					<h1>User Settings</h1>
					<p>
						<strong>Email:</strong> {accountInfo.email}
					</p>
					<p>
						<strong>First Name:</strong> {accountInfo.firstName}
					</p>
					<p>
						<strong>Last Name:</strong> {accountInfo.lastName}
					</p>
					<Button className='bg-red-600' variant='danger' onClick={BeforeDeleteAccount}>
						Remove Account
					</Button>
				</Col>
			</Row>

			<DeleteAccount show={showDelete} onHide={AfterDeleteAccount} email={accountInfo.email}></DeleteAccount>
		</Container>
	)
}

export { UserSettings }
