import axios, { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios'
import store from 'store'
import { logout } from 'store/auth'
import { getAuthToken } from 'services/auth'

// This is the base URL where requests comes from
const http = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT,
	withCredentials: false,
	timeout: 90000,
	headers: {
		'Content-Type': 'application/json',
	},
})

/*
 * The interceptor here ensures that we check for the token in the cookie every time a http request is made
 */
http.interceptors.request.use(
	(config: InternalAxiosRequestConfig) => {
		if (config.headers === undefined) {
			config.headers = {} as AxiosRequestHeaders
		}
		const token = getAuthToken()
		if (token) {
			config.headers['Authorization'] = `Bearer ${token.toString()}`
		}

		return config
	},
	(error) => {
		return Promise.reject(error)
	},
)

// Add a response interceptor
http.interceptors.response.use(
	function (response) {
		return response
	},
	function (error) {
		if (!error.response) {
			return Promise.reject(error)
		}
		switch (error.response.status) {
			case 401:
				httpLogout()
				return Promise.reject(error)
			case 404:
				return Promise.reject(error)
			case 422:
				return Promise.reject(error)
			case 429:
				// Throttle error
				return Promise.reject(error)

			default:
				break
		}
		return Promise.reject(error)
	},
)

export const httpLogout = () => {
	store.dispatch(logout())
}

export default http
