/*
 * Author: dizhong zhu
 * Date: 03/05/2023
 */

import React, { useEffect, useState } from 'react'
import { Card, ButtonGroup, ToggleButton, ListGroup, Button } from 'react-bootstrap'
import { ClothItemCell } from './clotItemCell'
import { Api_CreateClothItems, Api_DeleteClothItems, Api_GetClothItems, Api_GetSizeChart, Api_UpdateClothItems } from '../../../../../apis/sizes'
import { APIErrorHandler } from '../../../../../apis/apiErros'
import { DisplayAlert, DisplaySuccess } from '../../../../../widgets/DisplayAlert'
import { v4 as uuidv4 } from 'uuid'
import { fetchImage } from '../../../../../utils/data_process'

const isDuplicateName = (clothes, gender, index, value) => {
	const existingIndex = clothes[gender].findIndex((item, idx) => item.name === value && idx !== index)
	return existingIndex !== -1
}

async function parse_cloth_items(cloth_items) {
	// for loop the cloth_items
	let parse_cloth_items = {
		male: [],
		female: [],
		// nonBinary: []
	}

	for (let i = 0; i < cloth_items.length; i++) {
		let cloth = cloth_items[i]
		const image = await fetchImage(cloth_items[i].image_url)

		let processedImageBlob = ''
		if (image.type === '.svg') {
			processedImageBlob = image.dataUrl.replace('data:application/octet-stream', 'data:image/svg+xml')
		} else {
			processedImageBlob = image.dataUrl.replace('data:application/octet-stream', `data:${image.type}`)
		}
		cloth.image_blob = processedImageBlob
		cloth.image_type = image.type

		if (cloth_items[i].gender === 'male') {
			parse_cloth_items.male.push(cloth)
		} else if (cloth_items[i].gender === 'female') {
			parse_cloth_items.female.push(cloth)
		} else {
			parse_cloth_items.nonBinary.push(cloth)
		}
	}

	return parse_cloth_items
}

function BoardClothItems({ domain }) {
	const handleError = APIErrorHandler()

	const [gender, setGender] = useState('male')
	const [clothItems, setClothItems] = useState({
		male: [],
		female: [],
		// nonBinary: []
	})
	const [changes, setChanges] = useState({}) // The updated cloth items
	const [createdItems, setCreatedItems] = useState([])

	const [sizeCharts, setSizeCharts] = useState({
		male: [],
		female: [],
	})

	const handleNameChange = (e, gender, index) => {
		const { value } = e.target
		const clothes = { ...clothItems }
		clothes[gender][index].name = value
		setClothItems(clothes)
	}

	const toggleNameEdit = (gender, index) => {
		const clothes = { ...clothItems }

		if (clothes[gender][index].isEditing === true) {
			if (isDuplicateName(clothes, gender, index, clothes[gender][index].name)) {
				DisplayAlert('Error: Item name already exists')
				return
			}

			setChanges((prevChanges) => {
				return {
					...prevChanges,
					[clothes[gender][index].uuid]: { type: 'update', item: clothes[gender][index] },
				}
			})
		}
		clothes[gender][index].isEditing = !clothes[gender][index].isEditing
		setClothItems(clothes)
	}

	const handleDelete = (gender, index) => {
		const clothes = { ...clothItems }
		const item = clothes[gender][index]
		clothes[gender].splice(index, 1)
		setClothItems(clothes)

		// Store the changes, update to API when save press
		setChanges((prevChanges) => {
			return {
				...prevChanges,
				[item.uuid]: { type: 'delete', item: item },
			}
		})
	}

	const handleImageUpload = (e, gender, index) => {
		const file = e.target.files[0]
		if (file) {
			const reader = new FileReader()
			reader.onloadend = () => {
				const clothes = { ...clothItems }
				clothes[gender][index].image_blob = reader.result
				// Get file extension
				const fileExtension = file.name.split('.').pop()
				clothes[gender][index].image_type = '.' + fileExtension
				setClothItems(clothes)

				// Update changes state
				setChanges((prevChanges) => {
					return {
						...prevChanges,
						[clothes[gender][index].uuid]: { type: 'update', item: clothes[gender][index] },
					}
				})
			}
			reader.readAsDataURL(file)
		}
	}

	const handleSizeChartChange = (selectId, gender, index) => {
		const clothes = { ...clothItems }
		clothes[gender][index].size_chart = sizeCharts[gender][selectId]
		setClothItems(clothes)

		setChanges((prevChanges) => {
			return {
				...prevChanges,
				[clothes[gender][index].uuid]: { type: 'update', item: clothes[gender][index] },
			}
		})

		console.log(clothItems)
	}

	const handleAddItem = () => {
		const newItem = {
			uuid: uuidv4(),
			name: 'New cloth',
			gender: gender,
			disp_measure: 'chest, waist',
			size_chart: '',
			image_blob: '', // Add a default image blob if necessary
			image_type: '',
			isEditing: true,
		}
		const clothes = { ...clothItems }
		clothes[gender].push(newItem)
		setClothItems(clothes)

		// Add the UUID of the new item to createdItems
		setCreatedItems((prevCreatedItems) => [...prevCreatedItems, newItem.uuid])
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const token = localStorage.getItem('token')
				await Api_GetClothItems(domain, token).then(async (data) => {
					const clothItems = await parse_cloth_items(data)
					setClothItems(clothItems)
				})

				await Api_GetSizeChart(domain, token).then((data) => {
					let sizeChartName = {
						male: [],
						female: [],
					}
					for (let i = 0; i < data?.length; i++) {
						if (data[i].gender === 'male') {
							sizeChartName.male.push(data[i].name)
						} else if (data[i].gender === 'female') {
							sizeChartName.female.push(data[i].name)
						}
					}
					setSizeCharts(sizeChartName)
				})
			} catch (error) {
				handleError(error)
			}
		}

		fetchData().then()
	}, [])

	const handleGenderChange = (val) => {
		setGender(val)
	}

	const handleSave = async () => {
		const deleteItems = []
		for (let itemId in changes) {
			try {
				const change = changes[itemId]
				// If the item is in createdItems, call the create API
				if (createdItems.includes(itemId)) {
					await Api_CreateClothItems(domain, localStorage.getItem('token'), change.item)
				} else if (change.type === 'update') {
					await Api_UpdateClothItems(domain, localStorage.getItem('token'), change.item)
				} else if (change.type === 'delete') {
					deleteItems.push(itemId)
				}
			} catch (error) {
				handleError(error)
				return
			}
		}

		if (deleteItems.length > 0) {
			try {
				await Api_DeleteClothItems(domain, localStorage.getItem('token'), deleteItems)
			} catch (error) {
				handleError(error)
				return
			}
		}

		// After successfully saving all changes, clear the changes state and createdItems state
		setChanges({})
		setCreatedItems([])
		DisplaySuccess('Successfully saved changes')
	}

	return (
		<Card>
			<Card.Header className='d-flex justify-content-between align-items-center'>
				<ButtonGroup toggle>
					{['male', 'female'].map((value) => (
						<ToggleButton
							key={value}
							type='radio'
							variant='outline-primary'
							name='gender'
							value={value}
							checked={gender === value}
							onClick={() => handleGenderChange(value)}>
							{value}
						</ToggleButton>
					))}
				</ButtonGroup>
				<Button className='bg-blue-600' variant='primary' onClick={handleAddItem}>
					Add Item
				</Button>
			</Card.Header>
			<Card.Body>
				<ListGroup>
					{clothItems[gender].map((item, index) => (
						<ListGroup.Item key={index}>
							<ClothItemCell
								item={item}
								sizeChartList={sizeCharts[gender]}
								onToggleEdit={() => toggleNameEdit(gender, index)}
								onChange={(e) => handleNameChange(e, gender, index)}
								onEnter={() => toggleNameEdit(gender, index)}
								onDelete={() => handleDelete(gender, index)}
								onImage={(e) => handleImageUpload(e, gender, index)}
								onSize={(e) => handleSizeChartChange(e, gender, index)}
							/>
						</ListGroup.Item>
					))}
				</ListGroup>
			</Card.Body>
			<Card.Footer>
				<Button className='bg-blue-600' variant='primary' onClick={handleSave}>
					Save
				</Button>
			</Card.Footer>
		</Card>
	)
}

export { BoardClothItems }
