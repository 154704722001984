interface NotFoundProps {
	message: string
}

export const NotFound = ({ message }: NotFoundProps) => {
	return (
		<div>
			<p className='text-lg'>{message ?? 'Not Found'}</p>
		</div>
	)
}
