import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

function Chart({ stats }: any) {
	const data = {
		labels: ['Used', 'Non-Used'],
		datasets: [
			{
				label: '# of Widget Usage',
				data: [stats['totalNoofOrders'].true, stats['totalNoofOrders'].false],
				backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
				borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
				borderWidth: 1,
			},
		],
	}

	const headerClass = 'py-2 px-4 border-b border-gray-200 font-semibold uppercase text-gray-600 bg-gray-200'
	const rowClass = 'py-2 px-4 border-b bg-zinc-50 border-gray-200'

	return (
		<div>
			<div className='grid md:flex justify-around gap-4'>
				<div className='h-72 mb-20 px-5'>
					<p className='text-lg font-semibold mb-4'>Checkouts</p>
					<Pie data={data} />
				</div>
				<div className='mt-2'>
					<p className='text-lg font-semibold mb-4'>Metrics</p>
					<table className='min-w-full bg-gray-100 border border-gray-200'>
						<thead>
							<tr>
								<th className={`${headerClass} w-52`}></th>
								<th className={`${headerClass} w-40`}>Usage</th>
								<th className={`${headerClass} w-40`}>Non-usage</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className={rowClass}>Conversion</td>
								<td className={rowClass}>{stats['conversion'].true * 100}%</td>
								<td className={rowClass}>{stats['conversion'].false * 100}%</td>
							</tr>
							<tr>
								<td className={rowClass}>AOV (£)</td>
								<td className={rowClass}>{stats['AOV'].true.toFixed(2)}</td>
								<td className={rowClass}>{stats['AOV'].false.toFixed(2)}</td>
							</tr>
							<tr>
								<td className={rowClass}>No. of Checkouts</td>
								<td className={rowClass}>{stats['totalNoofOrders'].true}</td>
								<td className={rowClass}>{stats['totalNoofOrders'].false}</td>
							</tr>
							<tr>
								<td className={rowClass}>Total Checkout (£)</td>
								<td className={rowClass}>{stats['checkoutAmount'].true}</td>
								<td className={rowClass}>{stats['checkoutAmount'].false}</td>
							</tr>
							<tr>
								<td className={rowClass}>Returns</td>
								<td className={rowClass}>{stats['returnOrder'].true}</td>
								<td className={rowClass}>{stats['returnOrder'].false}</td>
							</tr>
							<tr>
								<td className={rowClass}>Return Rate</td>
								<td className={rowClass}>{stats['returnRate'].true * 100}%</td>
								<td className={rowClass}>{stats['returnRate'].false * 100}%</td>
							</tr>
							<tr>
								<td className={rowClass}>Refund Amount (£)</td>
								<td className={rowClass}>{stats['refundAmount'].true}</td>
								<td className={rowClass}>{stats['refundAmount'].false}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className='mt-2'>
					<p className='text-lg font-semibold mb-4'>Completions</p>
					<table className='min-w-full bg-gray-100 border border-gray-200'>
						<thead>
							<tr>
								<th className={`${headerClass} w-52`}></th>
								<th className='py-2 px-4 border-b border-gray-200 font-semibold text-gray-600 bg-gray-200 w-40'>Completions</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className={rowClass}>Quick sizing</td>
								<td className={rowClass}>{stats['completion'].quick_sizing * 100}%</td>
							</tr>
							<tr>
								<td className={rowClass}>Full Scan</td>
								<td className={rowClass}>{stats['completion'].full_scan * 100}%</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export { Chart }
