/*
 * Author: dizhong zhu
 * Date: 10/03/2023
 */

import { useState, useEffect } from 'react'
import { Card, Button, Container, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import 'pages/Dashboard/Tenants/tenants.css'
import { useAppDispatch, useAppSelector } from 'store'
import { getTenants } from 'store/tenant'
import { ModalCreate } from 'pages/Dashboard/Tenants/Modals'
import { TENANTS_ROUTE } from 'routes/path'

const Tenants = () => {
	const dispatch = useAppDispatch()

	const [showCreate, setShowCreate] = useState(false)

	const navigate = useNavigate()

	useEffect(() => {
		dispatch(getTenants())
	}, [])

	const { tenants } = useAppSelector((state) => state.tenant)

	const handleCreateTenant = async () => {
		setShowCreate(false)
		dispatch(getTenants())
	}

	const handleCardClick = (tenant: any, index: any) => {
		navigate(`${TENANTS_ROUTE}/${index}`, { state: { domain: tenant.domain } })
	}

	return (
		<Container fluid>
			<Row>
				<h3>Tenants List</h3>
			</Row>
			<Row>
				{tenants.map((tenant, index) => (
					<Col md={4} key={index} style={{ marginBottom: '15px' }}>
						<Card className='tenant-hover' onClick={() => handleCardClick(tenant, index)} style={{ cursor: 'pointer' }}>
							<Card.Header className='d-flex justify-content-between align-items-center'>
								<span>{tenant.domain}</span>
								{/*<CloseButton style={{outline: 'none'}}*/}
								{/*             onClick={() => BeforeDeleteTenant(tenant.domain)}/>*/}
							</Card.Header>
							<Card.Body>
								<Card.Title>{tenant.name}</Card.Title>
								<Card.Subtitle className='mb-2 text-muted'>{tenant.domain}</Card.Subtitle>
								<Card.Text>{tenant.description}</Card.Text>
							</Card.Body>
						</Card>
					</Col>
				))}
				<Col md={4}>
					<Button className='bg-blue-600' variant='primary' onClick={() => setShowCreate(true)}>
						Add Tenant
					</Button>
				</Col>
			</Row>

			<ModalCreate show={showCreate} onHide={handleCreateTenant} />
		</Container>
	)
}

export default Tenants
