import Layout from 'components/Layout'
import Initialization from 'middleware/initialize'
import { Fragment, useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import routes from 'routes'

const App = () => {
	const setViewHeight = () => {
		const vh = window.innerHeight * 0.01
		document.documentElement.style.setProperty('--vh', `${vh}px`)
	}

	useEffect(() => {
		setViewHeight()
		window.addEventListener('resize', () => {
			setViewHeight()
		})
	})

	const views = useRoutes(routes)

	return <Fragment>{Initialization({ children: <Layout>{views}</Layout> })}</Fragment>
}

export default App
