/*
 * Author: dizhong zhu
 * Date: 17/03/2023
 */

import { Button, Modal } from 'react-bootstrap'
import React from 'react'
import { Api_DeleteAccount } from '../../../apis/accounts'
import { APIErrorHandler } from '../../../apis/apiErros'

const DeleteAccount = ({ show, onHide, email }) => {
	const handleError = APIErrorHandler()

	const handleDeleteAccount = async () => {
		const token = localStorage.getItem('token')
		try {
			const success = await Api_DeleteAccount(email, token)
			if (success) {
				onHide(success)
			}
		} catch (error) {
			handleError(error)
		}
	}

	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>Delete Tenant</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					Are you sure you want to delete account <strong>{email}</strong>?
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Button className='bg-gray-600' variant='secondary' onClick={() => onHide(false)}>
					Close
				</Button>
				<Button className='bg-blue-600' variant='primary' onClick={handleDeleteAccount}>
					Confirm
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export { DeleteAccount }
