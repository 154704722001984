/*
 * Author: dizhong zhu
 * Date: 09/03/2023
 */

import React, { useState } from 'react'
import logo from '../../../assets/png/logo.png'
import { FormInput } from '../../../widgets/FormInput'
import { NavLink, useNavigate } from 'react-router-dom'
import { Api_SignUp } from '../../../apis/sign'
import { SIGN_IN } from '../../../common/routePaths'
import { Button, Card, Container, Form } from 'react-bootstrap'
import { DisplayAlert, DisplaySuccess } from '../../../widgets/DisplayAlert'

function SignUp() {
	const navigate = useNavigate()

	const [account, setAccount] = useState({
		email: '',
		password: '',
		firstName: '',
		lastName: '',
	})

	const handleInputChange = ({ currentTarget: input }) => {
		const updatedAccount = { ...account, [input.name]: input.value }
		setAccount(updatedAccount)
	}

	const handleSignUp = async (event) => {
		event.preventDefault()
		// Here you can add your code to handle the sign-up process, like sending the form data to your backend API.
		try {
			const success = await Api_SignUp(account)
			if (success) {
				DisplaySuccess('Sign up successfully')
				navigate(`${SIGN_IN}`)
			}
		} catch (error) {
			DisplayAlert(error.message)
		}
	}

	return (
		<React.Fragment>
			<Container className='d-flex align-items-center justify-content-center' style={{ height: '100vh' }}>
				<Card className='m-3' style={{ minWidth: '800px' }}>
					<Card.Header className='text-center'>
						<img src={logo} alt='Logo' width={'50%'} />
					</Card.Header>
					<Card.Body>
						<Form onSubmit={handleSignUp}>
							<FormInput
								name={'email'}
								label={'Business Email'}
								type={'email'}
								placeholder={'Enter email'}
								value={account.email}
								onChange={handleInputChange}
							/>
							<FormInput
								name={'password'}
								label={'Password'}
								type={'password'}
								placeholder={'password'}
								value={account.password}
								onChange={handleInputChange}
							/>
							<FormInput name={'firstName'} label={'First Name'} type={'text'} value={account.firstName} onChange={handleInputChange} />
							<FormInput name={'lastName'} label={'Last Name'} type={'text'} value={account.lastName} onChange={handleInputChange} />
							<div className='text-center'>
								<Button className='bg-blue-600' type='submit'>
									Sign Up
								</Button>
							</div>
						</Form>
						<div className='text-center'>
							Already have an account?
							<div>
								<Button variant='link'>
									<NavLink className={'nav-item nav-link'} to='/login'>
										Sign in here
									</NavLink>
								</Button>
							</div>
						</div>
					</Card.Body>
				</Card>
			</Container>
		</React.Fragment>
	)
}

export default SignUp
