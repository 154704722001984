import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getSizeChartRequest } from 'services/size'
import { RootState } from 'store'

export const initialState: SizeState = {
	sizecharts: [],
	clothItems: [],
	isLoading: true,
	errors: [],
}

export const getSizeCharts = createAsyncThunk<SizeChart[], string>('size/size_chart', async (domain: string, { dispatch }) => {
	dispatch(setIsLoading(true))

	return getSizeChartRequest(domain)
		.then((res: ListSizeChartResponse) => {
			dispatch(setSizeCharts(res.data))
			return res.data
		})
		.catch((err: any) => {
			dispatch(setError([err.response.data.message]))
		})
		.finally(() => dispatch(setIsLoading(false))) as Promise<SizeChart[]>
})

export const sizeSlice = createSlice({
	name: 'size',
	initialState,
	reducers: {
		setSizeCharts: (state, { payload }: PayloadAction<SizeChart[]>) => {
			state.sizecharts = payload
		},
		setError: (state, { payload }: PayloadAction<any>) => {
			state.errors = payload
		},
		setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.isLoading = payload
		},
	},
})

export const { setSizeCharts, setIsLoading, setError } = sizeSlice.actions

export const sizeSelector = (state: RootState) => state.size

export default sizeSlice.reducer
