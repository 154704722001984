import Cookies from 'js-cookie'

class CookieService {
	cookie = Cookies

	set(key: string, value: any) {
		value = JSON.stringify(value)
		this.cookie.set(key, value)
		return true
	}

	get(key: string) {
		const value = this.cookie.get(key) as string
		try {
			return JSON.parse(value)
		} catch (e) {
			return null
		}
	}

	delete(name: string) {
		this.cookie.remove(name)
	}

	deleteAll() {
		const cookies = this.cookie.get()
		for (const name in cookies) {
			this.delete(name)
		}
	}
}

export default new CookieService()
