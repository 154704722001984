import {useEffect, useState} from 'react'
import {Chart} from './Chart'
import TenantSideBar from 'widgets/TenantSideBar'
import {useAppDispatch, useAppSelector} from 'store'
import {getTransactionStats} from 'store/analytics'
import PageLoader from 'components/PageLoader'
import {getTenants} from 'store/tenant'

export default function Dashboard() {
    const dispatch = useAppDispatch()

    const [currentDomain, setCurrentDomain] = useState('')

    const {tenants, isLoadingTenants} = useAppSelector((state) => state.tenant)
    const {transactionStat, isLoadingTransactionStat} = useAppSelector((state) => state.analytics)

    useEffect(() => {
        dispatch(getTenants())
    }, [])

    useEffect(() => {
        if (tenants.length > 0) {
            handleTenantClick(tenants[0].domain)
        }
    }, [tenants])

    const handleTenantClick = async (domain: string) => {
        console.log('click domain: ' + domain)
        setCurrentDomain(domain)

        dispatch(getTransactionStats(domain))
    }

    return (
        <PageLoader open={isLoadingTenants}>
            {tenants.length > 0 ? (
                <div className='mt-2'>
                    <div className='grid md:flex'>
                        <div className='d-flex flex-column px-4 mt-12'
                             style={{maxHeight: 'calc(100vh - 168px)'}}
                        >
                            <TenantSideBar tenants={tenants} currentDomain={currentDomain} OnTenantClick={handleTenantClick}/>
                        </div>

                        {!isLoadingTransactionStat && (
                            <div style={{overflowY: 'scroll', height: '100%'}}>
                                {currentDomain !== '' && <Chart domain={currentDomain} stats={transactionStat}/>}
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <p>No tenants available. Please add a tenant first.</p>
            )}
        </PageLoader>
    )
}
