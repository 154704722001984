import React from 'react'
import { AppRouteConfig } from 'types/navigation'
import { Login } from 'views/auth/Login'
import { BASE_ROUTE } from './path'
import errorRoutes from './error'
import authRoutes from './auth'
import dashboardRoutes from './dashboard'

const baseRoute: AppRouteConfig[] = [
	{
		path: BASE_ROUTE,
		element: React.createElement(Login),
	},
]

export default [...baseRoute, ...errorRoutes, ...authRoutes, ...dashboardRoutes]
