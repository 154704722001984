/*
 * Author: dizhong zhu
 * Date: 20/03/2023
 */

import axios from 'axios'
import { APIThrowError } from './apiErros'

const Api_GetScans = (token) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}

	return axios
		.get(`${process.env.REACT_APP_API_ENDPOINT}/dashboard/scans`, config)
		.then((response) => {
			if (response.data.status === true) {
				return response.data?.data ?? {}
			}
		})
		.catch((error) => {
			APIThrowError(error)
		})
}

const Api_GetScanURL = (token, scans) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}

	const data = scans.map((scan) => {
		return {
			scan_id: scan.uuid,
			domain: scan.domain,
		}
	})

	return axios
		.post(`${process.env.REACT_APP_API_ENDPOINT}/dashboard/scans-download`, data, config)
		.then((response) => {
			if (response.data.status === true) {
				return response.data?.data ?? {}
			}
		})
		.catch((error) => {
			APIThrowError(error)
		})
}

export { Api_GetScans, Api_GetScanURL }
