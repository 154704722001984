/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect } from 'react'
import { Location, NavigateFunction, RoutesProps, useLocation, useNavigate } from 'react-router-dom'
import { DASHBOARD_ROUTE, LOGIN_ROUTE } from 'routes/path'
import { getAuthAccount } from 'services/auth'
import { AppRouteConfig } from 'types/navigation'
import { useAppDispatch } from 'store'
import { setUserData } from 'store/auth'
import { setLayoutPageLoader } from 'store/layout'
import { getCurrentRouteContext } from 'utils/route'
import { getTenants } from 'store/tenant'

let user: AuthUser | null
let matched: AppRouteConfig

const checkJwtAuth = async (location: Location, navigate: NavigateFunction) => {
	user = getAuthAccount()

	matched = getCurrentRouteContext(location)

	if (matched.isError) {
		navigate(matched.path!)
	}

	if (matched.isolated) {
		return null
	}

	/**
	 * User is authenticated but they are trying to access a public view
	 * OR
	 * User is authenticated and trying to access the app
	 */
	if ((!matched.restricted && user) || (matched.restricted && user)) {
		return redirectToApp(navigate)
	}

	/**
	 * User is not authenticated but they are trying to access a restricted view
	 * OR
	 * User is not authenticated and they are trying to access a public
	 * view
	 */
	if ((matched.restricted && !user) || (!matched.restricted && !user)) {
		return redirectToLogin(navigate)
	}

	return null
}

const redirectToLogin = (navigate: NavigateFunction) => {
	if (matched.restricted && !user) {
		return navigate(LOGIN_ROUTE)
	}
}
const redirectToApp = (navigate: NavigateFunction) => {
	/**  User is trying to access a public view but they are already
	 * authenticated
	 * */
	if (!matched.restricted && user) {
		return navigate(DASHBOARD_ROUTE)
	}
}

const Initialization = (props: RoutesProps) => {
	const { children } = props
	const location = useLocation()
	const dispatch = useAppDispatch()

	const navigate: NavigateFunction = useNavigate()

	useEffect(() => {
		checkJwtAuth(location, navigate).then(() => {
			if (user) {
				dispatch(setLayoutPageLoader(true))
				dispatch(setUserData(user))

				Promise.all([dispatch(getTenants())]).finally(() => {
					dispatch(setLayoutPageLoader(false))
				})
			}
		})
	}, [])

	return children
}

export default Initialization
