/*
 * Author: dizhong zhu
 * Date: 13/03/2023
 */

import React from 'react'
import {ListGroup} from 'react-bootstrap'

interface TenantT {
    domain: string;
    name: string;
}

interface props {
    tenants: TenantT[];
    currentDomain: string;
    OnTenantClick: (domain: string) => void;
}

const TenantSideBar: React.FC<props> = ({tenants, currentDomain, OnTenantClick}) => {
    return (
        <div className='flex-grow-1 overflow-auto'>
            <ListGroup>
                {tenants.map((tenant, index) => (
                    <ListGroup.Item
                        className='px-5 py-3'
                        key={index}
                        action
                        active={tenant.domain === currentDomain}
                        onClick={() => OnTenantClick(tenant.domain)}>
                        {tenant.name}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    )
}

export default TenantSideBar
