import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getTranactionStatsRequest } from 'services/analytics'
import { RootState } from 'store'

export const initialState: AnalyticsState = {
	transactionStat: [],
	isLoadingTransactionStat: true,
	transactionStatError: [],
}

export const getTransactionStats = createAsyncThunk<Tenant[], string>('analytics/list', async (domain: string, { dispatch }) => {
	dispatch(isLoadingTransactionStat(true))

	return getTranactionStatsRequest(domain)
		.then((res: ListTransactionStatResponse) => {
			dispatch(setTransactionStat(res.data))
			return res.data
		})
		.catch((err: any) => {
			dispatch(setTransactionStatErr([err.response.data.message]))
		})
		.finally(() => dispatch(isLoadingTransactionStat(false))) as Promise<Tenant[]>
})

export const analyticSlice = createSlice({
	name: 'analytics',
	initialState,
	reducers: {
		setTransactionStat: (state, { payload }: PayloadAction<TransactionStat[]>) => {
			state.transactionStat = payload
		},
		setTransactionStatErr: (state, { payload }: PayloadAction<any>) => {
			state.transactionStatError = payload
		},

		isLoadingTransactionStat: (state, { payload }: PayloadAction<boolean>) => {
			state.isLoadingTransactionStat = payload
		},
	},
})

export const { setTransactionStat, isLoadingTransactionStat, setTransactionStatErr } = analyticSlice.actions

export const analyticSelector = (state: RootState) => state.tenant

export default analyticSlice.reducer
