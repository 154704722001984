/*
 * Author: Muhammad Husnain
 * Date: 29/03/2024
 */
import {useMemo, useState} from 'react'
import {Button, Table, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useReactTable, getCoreRowModel, getSortedRowModel, flexRender, getFilteredRowModel, getPaginationRowModel} from '@tanstack/react-table'
import {DebouncedInput} from 'components/DebouncedInput'
import {IndeterminateCheckbox} from 'components/IndeterminateCheckbox'

function ScanDispTable({scans, OnScanClick, isDownloading, handleDownload}) {
    const columns = useMemo(
        () => [
            {
                id: 'select',
                header: ({table}) => (
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                ),
                cell: ({row}) => (
                    <div className="px-1">
                        <IndeterminateCheckbox
                            {...{
                                checked: row.getIsSelected(),
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler(),
                            }}
                        />
                    </div>
                ),
            },
            {
                accessorKey: 'uuid',
                header: "UUID",
                cell: ({getValue}) => {
                    const uuid = getValue()
                    return (
                        <Button variant='link' onClick={() => OnScanClick(uuid)}>
                            {uuid}
                        </Button>
                    )
                }
            },
            {
                accessorKey: "gender",
                header: "Gender",
            },
            {
                accessorKey: "email",
                header: "Email",
            },
            {
                accessorKey: "first_name",
                header: "First Name",
            },
            {
                accessorKey: "last_name",
                header: "Last Name",
            },
            {
                accessorKey: "source",
                header: "Scan Source",
            },
            {
                accessorKey: "scan_at",
                header: "Last Scan",
                cell: ({getValue}) => {
                    return new Date(getValue()).toLocaleString()
                },
            },
            {
                accessorKey: "size",
                header: "Size",
            },
            {
                accessorKey: "status",
                header: "Status",
            },
            {
                accessorKey: "complete",
                header: "Scan Complete",
                cell: ({getValue}) => {
                    return getValue() ? "Y" : "N"
                },
            },
            {
                accessorKey: "fails_reason",
                header: "Scan Failures",
                cell: ({ getValue }) => {
                    const value = getValue()
                    return (
                        <div className='truncate-text'>
                            {value}
                        </div>
                    )
                },
            },
        ],)
    const data = useMemo(() => scans, [scans])
    const [globalFilter, setGlobalFilter] = useState('')
    const [sorting, setSorting] = useState([
        {
            id: 'scan_at',
            desc: true,
        }
    ])
    const [rowSelection, setRowSelection] = useState({})

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    })
    const tableInstance = useReactTable({
        columns,
        data,
        state: {
            sorting,
            globalFilter,
            pagination,
            rowSelection,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        onGlobalFilterChange: setGlobalFilter,
        //   debugTable: true,
    })
    return (
        <>
            <div className='my-2 flex justify-between'>
                <DebouncedInput
                    value={globalFilter ?? ''}
                    onChange={value => setGlobalFilter(String(value))}
                    className="p-2 font-lg shadow border border-block"
                    placeholder="Search all columns..."
                />
                <OverlayTrigger
                    placement='bottom'
                    delay={{show: 250, hide: 200}}
                    overlay={<Tooltip style={{position: 'fixed'}}>Download Selected</Tooltip>}>
                    <Button className='bg-blue-600' disabled={isDownloading || tableInstance.getSelectedRowModel().flatRows?.length === 0} onClick={(e) => {
                        const selectedRows = tableInstance.getSelectedRowModel().flatRows.map((row) => {
                            return row.original
                        })
                        handleDownload(true, selectedRows)

                    }}>
                        Download Selected Scan: {tableInstance.getSelectedRowModel().flatRows?.length}
                    </Button>
                </OverlayTrigger>
            </div>
            <Table striped bordered hover>
                <thead>
                {tableInstance.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                            return (
                                <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                        <div
                                            className={
                                                header.column.getCanSort()
                                                    ? 'cursor-pointer select-none'
                                                    : ''
                                            }
                                            onClick={header.column.getToggleSortingHandler()}
                                            title={
                                                header.column.getCanSort()
                                                    ? header.column.getNextSortingOrder() === 'asc'
                                                        ? 'Sort ascending'
                                                        : header.column.getNextSortingOrder() === 'desc'
                                                            ? 'Sort descending'
                                                            : 'Clear sort'
                                                    : undefined
                                            }
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {{
                                                asc: ' 🔼',
                                                desc: ' 🔽',
                                            }[header.column.getIsSorted()] ?? null}
                                        </div>
                                    )}
                                </th>
                            )
                        })}
                    </tr>
                ))}
                </thead>
                <tbody>
                {tableInstance
                    .getRowModel().rows.map(row => {
                        return (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => {
                                    return (
                                        <td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <div className="flex items-center gap-2">
                <button
                    className="border rounded-md px-3  text-blue-600 text-lg"
                    onClick={() => tableInstance.firstPage()}
                    disabled={!tableInstance.getCanPreviousPage()}
                >
                    {'«'}
                </button>
                <button
                    className="border rounded-md px-3  text-blue-600 text-lg"
                    onClick={() => tableInstance.previousPage()}
                    disabled={!tableInstance.getCanPreviousPage()}
                >
                    {'‹'}
                </button>
                <button
                    className="border rounded-md px-3  text-blue-600 text-lg"
                    onClick={() => tableInstance.nextPage()}
                    disabled={!tableInstance.getCanNextPage()}
                >
                    {'›'}
                </button>
                <button
                    className="border rounded-md px-3 text-blue-600 text-lg"
                    onClick={() => tableInstance.lastPage()}
                    disabled={!tableInstance.getCanNextPage()}
                >
                    {'»'}
                </button>
                <span className="flex items-center gap-1 text-sm">
          <div>Page</div>
          <strong>
            {tableInstance.getState().pagination.pageIndex + 1} of{' '}
              {tableInstance.getPageCount().toLocaleString()}
          </strong>
        </span>
                <span className="flex items-center gap-1 text-sm">
          | Go to page:
			<input
                type="number"
                defaultValue={tableInstance.getState().pagination.pageIndex + 1}
                onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    tableInstance.setPageIndex(page)
                }}
                className="border px-1 py-1 rounded-md w-16 outline-none"
            />
        </span>
                <select
                    className="border px-1 py-1 rounded-md outline-none text-sm"
                    value={tableInstance.getState().pagination.pageSize}
                    onChange={e => {
                        tableInstance.setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </>
    )
}

export {ScanDispTable}
