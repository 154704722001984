import React, { useEffect, useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import '../tenants.css'
import { PasswordInput } from '../../../../widgets/FormInput'

export const TenantInfo = ({ info }) => {
	const [formValues, setFormValues] = useState({
		name: info?.name || '',
		description: info?.description || '',
		domain: info?.domain || '',
		api_key: info?.api_key || '',
	})

	useEffect(() => {
		setFormValues({
			name: info?.name || '',
			description: info?.description || '',
			domain: info?.domain || '',
			api_key: info?.api_key || '',
		})
	}, [info])

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setFormValues((prevState) => ({ ...prevState, [name]: value }))
	}

	return (
		<Form className='bold-form-labels'>
			<Form.Group className='mb-3' controlId='name'>
				<Form.Label>Name</Form.Label>
				<Form.Control
					type='text'
					placeholder='Enter name'
					name='name'
					value={formValues.name}
					onChange={handleInputChange}
					className='input-field'
				/>
				<Form.Text className='text-muted'>Give a name to your tenant store</Form.Text>
			</Form.Group>
			<Form.Group className='mb-3' controlId='description'>
				<Form.Label>Description</Form.Label>
				<Form.Control
					as='textarea'
					placeholder='Enter name'
					name='description'
					value={formValues.description}
					onChange={handleInputChange}
					className='input-field'
				/>
			</Form.Group>
			<Form.Group className='mb-3' controlId='billing'>
				<Form.Label>
					Your subscription plan: <strong>{info?.plan}</strong>
				</Form.Label>
				<br />
				{/* <Button className='bg-blue-600'>Change</Button> */}
				<br />
				<Form.Text className='text-muted'>Or change your plan in the billing page</Form.Text>
			</Form.Group>

			<PasswordInput
				controlId='api_key'
				label='API KEY'
				value={formValues.api_key}
				className='input-field'
				readOnly
				helpText='This is the API key for your store, please do not share it with anyone'
			/>
		</Form>
	)
}
