/*
 * Author: dizhong zhu
 * Date: 20/03/2023
 */

import { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import TenantSideBar from '../../widgets/TenantSideBar'
import { ScanDispEntry } from '../../pages/Dashboard/Scans/DispArea/ScanDispEntry'
import { useAppDispatch, useAppSelector } from 'store'
import { getScans } from 'store/scan'
import { getTenants } from 'store/tenant'


export const Scan = () => {
	const dispatch = useAppDispatch()

	const [currentScans, setCurrentScans] = useState<any[]>([])
	const [currentDomain, setCurrentDomain] = useState('')


	const { tenants } = useAppSelector((state) => state.tenant)
	const { scans } = useAppSelector((state) => state.scan)

	const filterScanByDomain = (domain: string) => {
		if (domain === '') {
			return scans
		}

		const filteredScans = scans.filter((scan: any) => scan.domain === domain)
		return filteredScans.map((scan, index) => ({ ...scan, ID: index }))
	}

	useEffect(() => {
		dispatch(getTenants())
		dispatch(getScans())
	}, [])

	useEffect(() => {
		const filter_scans = filterScanByDomain(currentDomain)
		setCurrentScans(filter_scans)
	}, [scans, currentDomain])

	const handleTenantClick = async (domain: string) => {
		setCurrentDomain(domain)
	}

	return (
		<Container fluid className='h-100'>
			<Row className='h-100'>
				{/* The col show tenants and clickable*/}
				<Col md={2} className='d-flex flex-column h-100'
				style={{ maxHeight: 'calc(100vh - 168px)' }}
				>
					<TenantSideBar tenants={tenants} currentDomain={currentDomain} OnTenantClick={handleTenantClick} />
					<div className='d-flex justify-content-center my-2 w-full'>
						<Button className='bg-blue-600 w-full' onClick={() => setCurrentDomain('')}>
							Show All
						</Button>
					</div>
				</Col>
				<Col className='h-100'>
					<ScanDispEntry scans={currentScans}/>
				</Col>
			</Row>
		</Container>
	)
}
