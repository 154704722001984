/*
 * Author: dizhong zhu
 * Date: 09/03/2023
 */

import axios from 'axios'
import { APIThrowError } from './apiErros'
import { dataURLtoFileBlob } from '../utils/data_process'

const Api_CreateTenant = (tenantData, token) => {
	const data = {
		name: tenantData.name,
		domain: tenantData.domain,
		description: tenantData.description,
		plan: 'grow',
	}
	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}

	return axios
		.post(`${process.env.REACT_APP_API_ENDPOINT}/merchant/tenant`, data, config)
		.then(() => {
			return true
		})
		.catch((error) => {
			APIThrowError(error)
		})
}

const Api_DeleteTenant = (domain, token) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}

	return axios
		.delete(`${process.env.REACT_APP_API_ENDPOINT}/merchant/tenant?domain=${domain}`, config)
		.then(() => {
			return true
		})
		.catch((error) => {
			APIThrowError(error)
		})
}

const Api_ListTenants = (token) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}

	return axios
		.get(`${process.env.REACT_APP_API_ENDPOINT}/merchant/tenant`, config)
		.then((response) => {
			if (response.data.status === true) {
				return response.data?.data ?? []
			}
		})
		.catch((error) => {
			APIThrowError(error)
		})
}

const Api_UpdateTenantConfigurationImage = (domain, token, item) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			DOMAIN: domain,
		},
	}

	console.log(item)

	const formData = new FormData()
	formData.append('image', dataURLtoFileBlob(item.blob), `${item.name}${item.type}`)
	formData.append('name', 'image_cover')

	return axios
		.post(`${process.env.REACT_APP_API_ENDPOINT}/merchant/tenant/configurations/image-cover`, formData, config)
		.then((response) => {
			if (response.data.status === true) {
				return response.data?.data
			}
		})
		.catch((error) => {
			APIThrowError(error)
		})
}

const Api_UpdateTenantConfigurationMeasureConfig = (domain, token, val) => {
	const data = {
		name: 'measure_config',
		value: val,
	}

	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
			DOMAIN: domain,
		},
	}

	return axios
		.patch(`${process.env.REACT_APP_API_ENDPOINT}/merchant/tenant/configurations`, data, config)
		.then(() => {
			return true
		})
		.catch((error) => {
			APIThrowError(error)
		})
}

export { Api_CreateTenant, Api_DeleteTenant, Api_ListTenants, Api_UpdateTenantConfigurationImage, Api_UpdateTenantConfigurationMeasureConfig }
