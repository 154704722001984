/*
 * Author: dizhong zhu
 * Date: 03/05/2023
 */

import React, { useEffect, useRef } from 'react'
import { Button, Dropdown, Form, Image, InputGroup } from 'react-bootstrap'
import { Pencil, Trash, Check, CardImage } from 'react-bootstrap-icons'
import '../../tenants.css'
import '../../../../../widgets/EditableInput.css'

const ClothItemCell = ({ item, sizeChartList, placeholder, onToggleEdit, onDelete, onChange, onEnter, onImage, onSize }) => {
	const inputRef = useRef(null)
	const fileInputRef = useRef(null)

	useEffect(() => {
		if (item.isEditing && inputRef.current) {
			inputRef.current.focus()
			inputRef.current.select()
		}
	}, [item])

	const handleBlur = () => {
		if (item.isEditing) {
			onEnter()
		}
	}

	const handleFileButtonClick = () => {
		fileInputRef.current.click()
	}

	return (
		<InputGroup className='editable-input-group'>
			<div className='input-container'>
				<Image src={item.image_blob} className='thumbnail-img' />

				{item.isEditing ? (
					<Form.Control
						ref={inputRef}
						type='text'
						placeholder={placeholder}
						value={item.name}
						onChange={onChange}
						onKeyDown={(e) => e.key === 'Enter' && onEnter()}
						onBlur={handleBlur}
					/>
				) : (
					<InputGroup.Text>{item.name || placeholder}</InputGroup.Text>
				)}

				<Dropdown onSelect={onSize}>
					<Dropdown.Toggle className='bg-green-700' variant='success' id='dropdown-basic'>
						{item.size_chart || 'Select Size Chart'}
					</Dropdown.Toggle>

					<Dropdown.Menu>
						{sizeChartList.map((name, index) => (
							<Dropdown.Item key={index} eventKey={index}>
								{name}
							</Dropdown.Item>
						))}
					</Dropdown.Menu>
				</Dropdown>

				<Button variant='outline-secondary' className={item.isEditing ? 'check-btn' : ''} onClick={onToggleEdit}>
					{item.isEditing ? <Check /> : <Pencil />}
				</Button>
				<Button variant='outline-secondary' onClick={handleFileButtonClick}>
					<input ref={fileInputRef} type='file' accept='image/*' onChange={onImage} style={{ display: 'none' }} />
					<CardImage />
				</Button>
				<Button variant='outline-danger' onClick={onDelete}>
					<Trash />
				</Button>
			</div>
		</InputGroup>
	)
}

export { ClothItemCell }
