/*
 * Author: dizhong zhu
 * Date: 20/03/2023
 */

import {Button, Col, Container, OverlayTrigger, Row, Tooltip} from 'react-bootstrap'
import {ScanDispTable} from './ScanDispTable'
import React, {useEffect, useRef, useState} from 'react'
import {ScanTimeFilters} from './ScanTimeFilters'
import {ScanDetailModal} from './ScanDetailModal'
import {loadScanURL} from '../../../loadUserData'
import FileSaver from 'file-saver'
import JSZip from 'jszip'
import {ZipProgress} from '../comp/ZipProgress'
import Papa from 'papaparse'

function ScanDispEntry({scans}) {
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const [showDetail, setShowDetail] = useState(false)
    const [selectScanIdx, setSelectScanIdx] = useState(-1)

    const [filterScan, setFilterScan] = useState(scans) // The scan after applying filters
    // Zipping status
    const [isDownloading, setIsDownloading] = useState(false)
    const [zipProgress, setZipProgress] = useState(0)
    const isCancelled = useRef(false)
    // const [isCancelled, setIsCancelled] = useState(false);

    const handleStartDateChange = (date) => {
        setStartDate(date)
    }

    const handleEndDateChange = (date) => {
        setEndDate(date)
    }


    const handleScanClick = (uuid) => {
        const scanIndex = filterScan.findIndex((scan) => scan.uuid === uuid)
        setSelectScanIdx(scanIndex)
        setShowDetail(true)
    }

    const convertJsonToCsv = (jsonData) => {
        const csvData = []
        const header = Object.keys(jsonData)
        csvData.push(header)

        const data = Object.values(jsonData)
        csvData.push(data)

        const csv = Papa.unparse(csvData)
        return csv
    }

    const handleDownload = async (isSelectedDownloads = false, selectedScans = []) => {
        isCancelled.current = false
        setIsDownloading(true)
        setZipProgress(0)
        let fileURLs = []
        if (isSelectedDownloads) fileURLs = await loadScanURL(selectedScans)
        else fileURLs = await loadScanURL(filterScan)


        const zip = new JSZip()
        // const downloads = fileURLs.map(async (url, index) => {
        for (const [index, url] of fileURLs.entries()) {
            if (isCancelled.current) {
                console.log('Download cancelled')
                return
            }

            // Create a folder for each file and add the file to the folder
            const folderName = url.uuid
            const folder = zip.folder(folderName)
            const fetchFile = async (url) => {
                return fetch(url).then((response) => response.blob())
            }

            await Promise.all(
                Object.entries(url.files).map(([, value]) => {
                    return fetchFile(value?.url).then((blob) => {
                        if (value?.filename === 'measurements.json') {
                            var reader = new FileReader()
                            reader.onload = function () {
                                const csvData = convertJsonToCsv(JSON.parse(reader.result))
                                folder.file('measurements.csv', csvData)
                            }
                            reader.readAsText(blob)
                        }

                        return folder.file(value?.filename, blob)
                    })
                }),
            )

            setZipProgress(Math.round(((index + 1) / fileURLs.length) * 100))
        }

        // generate zip file
        const zipBlob = await zip.generateAsync({type: 'blob'})
        FileSaver.saveAs(zipBlob, 'data.zip')

        setIsDownloading(false)
    }

    const handleDownloadCancel = () => {
        isCancelled.current = true
        setIsDownloading(false)
        setZipProgress(0)
    }

    const prevScan = () => {
        if (selectScanIdx > 0) {
            setSelectScanIdx(selectScanIdx - 1)
        }
    }

    const nextScan = () => {
        if (selectScanIdx < filterScan.length - 1) {
            setSelectScanIdx(selectScanIdx + 1)
        }
    }

    const dateFilter = () => {
        let filteredScans = scans
        if (startDate) {
            filteredScans = filteredScans.filter((scan) => new Date(scan.scan_at) >= startDate)
        }
        if (endDate) {
            filteredScans = filteredScans.filter((scan) => new Date(scan.scan_at) <= endDate)
        }

        setFilterScan(filteredScans.map((scan, index) => ({...scan, ID: index})))
    }

    useEffect(() => {
        setFilterScan(scans)
    }, [scans])

    useEffect(() => {
        dateFilter()
    }, [startDate, endDate])

    return (
        <React.Fragment>
            <Container fluid className='h-100 d-flex flex-column'>
                <Row>
                    <Col md={8}>
                        <ScanTimeFilters
                            startDate={startDate}
                            OnStartDateChange={handleStartDateChange}
                            endDate={endDate}
                            OnEndDateChange={handleEndDateChange}
                        />
                    </Col>
                </Row>
                <Row className='flex-grow-1 overflow-hidden'>
                    <Col className='h-100 overflow-auto' style={{paddingRight: 0, paddingLeft: 0}}>
                        <ScanDispTable scans={filterScan} OnScanClick={handleScanClick} isDownloading={isDownloading} handleDownload={handleDownload}/>
                    </Col>
                </Row>
                {/* <Row>
					<ScanPagination paginationInfo={paginationInfo} OnPageChange={handleChangePage} />
				</Row> */}

                <ScanDetailModal
                    show={showDetail}
                    OnHide={() => setShowDetail(false)}
                    OnPrev={prevScan}
                    OnNext={nextScan}
                    scan={filterScan[selectScanIdx]}
                />

                <ZipProgress bProgress={isDownloading} progress={zipProgress} onCancel={handleDownloadCancel}/>
            </Container>
        </React.Fragment>
    )
}

export {ScanDispEntry}
