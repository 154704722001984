/*
 * Author: dizhong zhu
 * Date: 27/04/2023
 */

import React from 'react';

const Divider = ({ className }) => {
    const dividerStyle = {
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    };

    return <div className={`divider ${className}`} style={dividerStyle}></div>;
};

export default Divider;

