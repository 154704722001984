/*
 * Author: dizhong zhu
 * Date: 04/04/2023
 */

import { Button, Card, ProgressBar } from 'react-bootstrap'
import React from 'react'

function ZipProgress({ bProgress, progress, onCancel }) {
	return (
		bProgress && (
			<div className='zip-progress'>
				<Card style={{ width: '50%' }}>
					<Card.Header>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<strong>File downloading</strong>
							{/*<Button variant="close" onClick={onCancel} aria-label="Close"></Button>*/}
							<Button
								className='bg-red-600'
								variant='danger'
								onClick={onCancel}
								style={{ borderRadius: '10px', fontSize: '12px', padding: '5px 10px' }}>
								Cancel
							</Button>
						</div>
					</Card.Header>
					<Card.Body>
						<ProgressBar animated now={progress} label={`${progress}%`} style={{ width: '100%' }} />
					</Card.Body>
				</Card>
			</div>
		)
	)
}

export { ZipProgress }
