/*
 * Author: dizhong zhu
 * Date: 16/05/2023
 */

import { APIErrorHandler } from '../../../../../apis/apiErros'
import React, { useEffect, useState } from 'react'
import { Api_CreateSizeChart, Api_DeleteSizeChart, Api_GetSizeChart, Api_UpdateSizeChart } from '../../../../../apis/sizes'
import Divider from '../../../../../widgets/Divider'
import { Button, Card, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { Pencil, Trash } from 'react-bootstrap-icons'
import ReactAce from 'react-ace'
import { DisplayAlert } from '../../../../../widgets/DisplayAlert'

function parse_disp_measures(measures) {
	const body_parts = {}
	const size_headers = {}
	const measurements = {}

	Object.entries(measures.sizes).forEach(([part, sizes]) => {
		body_parts[part] = { name: part, isEditing: false }

		// Create a combined array of headers and measurements
		const combinedSizes = []
		Object.entries(sizes).forEach(([size, value]) => {
			combinedSizes.push({ header: size, measurement: value[0] })
		})
		// Sort the combined array by the measurements
		combinedSizes.sort((a, b) => a.measurement - b.measurement)

		size_headers[part] = combinedSizes.map((sizeObj) => sizeObj.header)
		measurements[part] = combinedSizes.map((sizeObj) => sizeObj.measurement)
	})

	let disp_data = []
	// Compare each size_headers set
	for (let part in size_headers) {
		const currentHeaders = [...size_headers[part]]
		// check if there is a matching header in disp_data
		const matchingData = disp_data.find((data) => {
			const dataHeaders = [...data.size_headers]
			// compare if both arrays have same elements
			return dataHeaders.length === currentHeaders.length && dataHeaders.every((value, index) => value === currentHeaders[index])
		})

		if (matchingData) {
			matchingData.body_parts.push(body_parts[part])
			matchingData.measurements.push(measurements[part])
		} else {
			disp_data.push({
				body_parts: [body_parts[part]],
				size_headers: currentHeaders,
				measurements: [measurements[part]],
			})
		}
	}

	return {
		name: measures.name,
		gender: measures.gender,
		data: disp_data,
	}
}

function BoardSizeChart({ domain }) {
	const handleError = APIErrorHandler()
	const [sizeChart, setSizeChart] = useState([])
	const [dispSizeChart, setDispSizeChart] = useState([
		{
			name: '',
			body_parts: [],
			data: [],
		},
	])

	const handleSizeChartSave = (json, index) => {
		const updatedSizeChart = [...sizeChart]
		const token = localStorage.getItem('token')

		try {
			if (!json.uuid || json.uuid === '') {
				updatedSizeChart[index] = json
				setSizeChart(updatedSizeChart)
				Api_CreateSizeChart(domain, token, [json]).then()
			} else {
				// uuid is not allowed to change, so we need to keep it
				json.uuid = updatedSizeChart[index].uuid
				updatedSizeChart[index] = json
				setSizeChart(updatedSizeChart)

				// call the api to update the size chart
				Api_UpdateSizeChart(domain, token, [json]).then()
			}
		} catch (error) {
			handleError(error)
		}
	}

	const handleSizeChartDelete = (index) => {
		const confirmed = window.confirm('Are you sure you want to delete this size chart?')
		if (confirmed) {
			const updatedSizeChart = [...sizeChart]
			// uuid is not allowed to change, so we need to keep it
			const deleteItem = updatedSizeChart[index]
			updatedSizeChart.splice(index, 1)
			setSizeChart(updatedSizeChart)

			// call the api to update the size chart
			try {
				const token = localStorage.getItem('token')
				Api_DeleteSizeChart(domain, token, deleteItem).then()
			} catch (error) {
				handleError(error)
			}
		}
	}

	const handleSizeChartAdd = () => {
		const newSizeChart = {
			name: '',
			gender: '',
			sizes: {},
		}

		// Append the new size chart to the existing state
		let updatedSizeChart
		if (Array.isArray(sizeChart)) {
			updatedSizeChart = [...sizeChart, newSizeChart]
		} else {
			updatedSizeChart = [newSizeChart]
		}
		setSizeChart(updatedSizeChart)

		// Create a new display size chart
		const newDispSizeChart = parse_disp_measures(newSizeChart)
		// Append the new display size chart to the existing state
		let updatedDispSizeChart
		if (Array.isArray(dispSizeChart)) {
			updatedDispSizeChart = [...dispSizeChart, newDispSizeChart]
		} else {
			updatedDispSizeChart = [newDispSizeChart]
		}
		setDispSizeChart(updatedDispSizeChart)
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const token = localStorage.getItem('token')
				await Api_GetSizeChart(domain, token).then((data) => {
					setSizeChart(data)
				})
			} catch (error) {
				handleError(error)
			}
		}

		fetchData().then()
	}, [])

	useEffect(() => {
		let dispSizeChart = []
		for (let i = 0; i < sizeChart?.length; i++) {
			dispSizeChart[i] = parse_disp_measures(sizeChart[i])
		}
		setDispSizeChart(dispSizeChart)
	}, [sizeChart])

	return (
		<React.Fragment>
			{dispSizeChart.map((chart, index) => (
				<React.Fragment key={index}>
					<CompDispSizechart
						sizeChart={chart}
						sizeChartJson={JSON.stringify(sizeChart[index], null, 2)}
						onSave={(e) => handleSizeChartSave(e, index)}
						onDelete={() => handleSizeChartDelete(index)}
					/>
					<Divider className='my-4' />
				</React.Fragment>
			))}
			<Button className='bg-blue-600' onClick={handleSizeChartAdd}>
				Add Size Chart
			</Button>
		</React.Fragment>
	)
}

function CompDispSizechart({ sizeChart, sizeChartJson, onSave, onDelete }) {
	const [isEditing, setIsEditing] = useState(false)
	const [editedSizeChartJson, setEditedSizeChartJson] = useState(sizeChartJson)

	useEffect(() => {
		// If the size chart is empty, trigger edit mode
		if (!sizeChart.name) {
			setIsEditing(true)
		}
	}, [sizeChart])

	const handleEdit = () => {
		setIsEditing(!isEditing)
	}

	const handleSave = () => {
		try {
			const parsedJson = JSON.parse(editedSizeChartJson)
			onSave(parsedJson)
			setIsEditing(false)
		} catch (e) {
			DisplayAlert('Invalid JSON format', e)
		}
	}

	return (
		<Card>
			<Card.Header className='d-flex justify-content-between'>
				<h5>{sizeChart.name}</h5>
				<div>
					<Button variant='outline-danger' onClick={onDelete}>
						<Trash />
					</Button>
					<Button variant='outline-secondary' onClick={handleEdit}>
						<Pencil />
					</Button>
				</div>
			</Card.Header>
			<Card.Body>
				{!isEditing ? (
					<Container>
						{sizeChart.data.map((data, index) => (
							<Row key={index}>
								<Col md={2}>
									<BodyPart chartName='body part' bodyParts={data.body_parts} />
								</Col>

								<Col md={10}>
									<SizeChartEditor sizeHeaders={data.size_headers} measurements={data.measurements} />
								</Col>
							</Row>
						))}
					</Container>
				) : (
					<Form.Group className='mb-3' controlId='widget_config'>
						<ReactAce
							mode='json'
							theme='github'
							value={editedSizeChartJson}
							onChange={setEditedSizeChartJson}
							style={{ width: '100%' }}
							setOptions={{
								showLineNumbers: true,
								tabSize: 2,
							}}
						/>
						<Button className='bg-blue-600' onClick={handleSave}>
							Save
						</Button>
					</Form.Group>
				)}
			</Card.Body>
		</Card>
	)
}

const BodyPart = ({ chartName, bodyParts }) => {
	return (
		<React.Fragment>
			<Table striped>
				<thead>
					<tr>
						<th className={'size-chart-cell'}>{chartName}</th>
					</tr>
				</thead>
				<tbody>
					{bodyParts?.map((bodyPart, index) => (
						<tr key={index} className={'size-chart-cell'}>
							<td>{bodyPart.name}</td>
						</tr>
					))}
				</tbody>
			</Table>
		</React.Fragment>
	)
}

const SizeChartEditor = ({ sizeHeaders, measurements }) => {
	return (
		<React.Fragment>
			<div className='size-chart-container'>
				<Table striped className='size-chart-table'>
					<thead>
						<tr>
							{sizeHeaders.map((header, index) => (
								<th key={index} className={'size-chart-cell'}>
									{header}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{Array.isArray(measurements) &&
							measurements.map((row, rowIndex) => (
								<tr key={rowIndex} className='size-chart-table-row'>
									{row.map((measurement, colIndex) => (
										<td className='size-chart-cell' key={colIndex}>
											{measurement}
										</td>
									))}
								</tr>
							))}
					</tbody>
				</Table>
			</div>
		</React.Fragment>
	)
}
export { BoardSizeChart }
