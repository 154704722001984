import { Api_GetAccountInfo } from '../apis/accounts'
import { Api_ListTenants } from '../apis/tenants'
import { Api_GetScanURL } from '../apis/scans'
import { APIErrorHandler } from '../apis/apiErros'

async function loadAccount() {
	try {
		const token = localStorage.getItem('token')
		const account = await Api_GetAccountInfo(token)
		return account
	} catch (error) {
		const handleError = APIErrorHandler()
		handleError(error)
	}
}

async function loadTenant() {
	try {
		const token = localStorage.getItem('token')
		const tenants = await Api_ListTenants(token)
		return tenants
	} catch (error) {
		const handleError = APIErrorHandler()
		handleError(error)
	}
}

async function loadScanURL(scans) {
	try {
		const token = localStorage.getItem('token')
		const urls = await Api_GetScanURL(token, scans)

		return urls
	} catch (error) {
		const handleError = APIErrorHandler()
		handleError(error)
	}
}

export { loadAccount, loadTenant, loadScanURL }
