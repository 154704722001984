import { Location, matchRoutes } from 'react-router-dom'
import routes from 'routes'
import errorRoutes from 'routes/error'
import { AppRouteConfig } from 'types/navigation'

export const getCurrentRouteContext = (location: Location): AppRouteConfig => {
	const matches = matchRoutes(routes, location)!

	if (matches === null) {
		return errorRoutes[0]
	}

	return matches[0].route
}
