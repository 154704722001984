/*
 * Author: dizhong zhu
 * Date: 20/03/2023
 */

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { CloseButton, Container, Form, Overlay, Popover, Row } from 'react-bootstrap'
import Pagination from 'react-bootstrap/Pagination'
import React, { useRef, useState } from 'react'

function ScanTimeFilters({ startDate, OnStartDateChange, endDate, OnEndDateChange }) {
	const [activePage, setActivePage] = useState(10)
	const [showDatePicker, setShowDatePicker] = useState(false)
	const customDateTarget = useRef(null)

	const handlePresetClick = (days) => {
		const start = new Date()
		const end = new Date()
		start.setDate(end.getDate() - days)
		OnStartDateChange(start)
		OnEndDateChange(end)
		setShowDatePicker(false)
	}

	const closeDatePicker = () => {
		setShowDatePicker(false)
	}

	const handleEndDatePickerChange = (date) => {
		OnEndDateChange(date)
		setShowDatePicker(false)
	}

	const handlePageClick = (page, e) => {
		// setActivePage(page);
		switch (page) {
			case 1:
				handlePresetClick(1)
				break
			case 2:
				handlePresetClick(7)
				break
			case 3:
				handlePresetClick(30)
				break
			case 4:
				handlePresetClick(365)
				break
			case 5:
				setShowDatePicker(true)
				// setCustomDateTarget(e.target)
				break
			default:
				break
		}
	}

	return (
		<Container fluid className='p-0'>
			<Row>
				<Pagination>
					<Pagination.Item active={activePage === 1} onClick={() => handlePageClick(1)}>
						1D
					</Pagination.Item>
					<Pagination.Item active={activePage === 2} onClick={() => handlePageClick(2)}>
						7D
					</Pagination.Item>
					<Pagination.Item active={activePage === 3} onClick={() => handlePageClick(3)}>
						30D
					</Pagination.Item>
					<Pagination.Item active={activePage === 4} onClick={() => handlePageClick(4)}>
						1Y
					</Pagination.Item>
					<Pagination.Item active={activePage === 5} onClick={() => handlePageClick(5)} ref={customDateTarget}>
						Custom
					</Pagination.Item>
				</Pagination>
				<Overlay target={customDateTarget.current} show={showDatePicker} placement='right'>
					<Popover>
						<Popover.Header className='d-flex justify-content-between align-items-center'>
							<span>Select Date Range</span>
							<CloseButton style={{ outline: 'none' }} onClick={closeDatePicker} />
						</Popover.Header>
						<Popover.Body>
							<Form.Label>Start Time:</Form.Label>
							<DatePicker
								selected={startDate}
								onChange={OnStartDateChange}
								selectsStart
								startDate={startDate}
								endDate={endDate}
								placeholderText='Start Date'
							/>
							<Form.Label>End Time:</Form.Label>
							<DatePicker
								selected={endDate}
								onChange={handleEndDatePickerChange}
								onClose={closeDatePicker}
								selectsEnd
								startDate={startDate}
								endDate={endDate}
								placeholderText='End Date'
							/>
						</Popover.Body>
					</Popover>
				</Overlay>
			</Row>
		</Container>
	)
}

export { ScanTimeFilters }
