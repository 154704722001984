/*
 * Author: dizhong zhu
 * Date: 09/03/2023
 */

import axios from 'axios'

const Api_SignUp = (userData) => {
	const data = {
		password: userData.password,
		email: userData.email,
		first_name: userData.firstName,
		last_name: userData.lastName,
	}
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	}

	return axios
		.post(`${process.env.REACT_APP_API_ENDPOINT}/merchant/sign-up`, data, config)
		.then(() => {
			return true
		})
		.catch((error) => {
			if (error.response && error.response.data && error.response?.data?.error[0]) {
				console.log(error.response?.data?.error[0])
				throw new Error(error.response?.data?.error[0])
			} else {
				throw new Error('An unknown error occurred')
			}
		})
}

const Api_AutoSignIn = (token) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}

	return axios
		.get(`${process.env.REACT_APP_API_ENDPOINT}/merchant/auto-sign-in`, config)
		.then((response) => {
			localStorage.setItem('token', response.data?.data?.token)
			return true
		})
		.catch((error) => {
			if (error.response && error.response.status === 401) {
				throw new Error('Authentication failed')
			} else {
				throw new Error(error.response?.data?.message + '\nreason:' + error.response?.data?.error[0])
			}
		})
}

export { Api_SignUp, Api_AutoSignIn }
