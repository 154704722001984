import cookie from 'services/cookie'
import http from 'services/http'

export async function loginRequest(formData: LoginInput): Promise<LoginResponse> {
	return (await http.post('/merchant/sign-in', formData)).data
}

export function getAuthToken() {
	return getAuthAccount() ? getAuthAccount()?.token : false
}

export function getAuthAccount(): AuthUser | null {
	return cookie.get('auth_user')
}
