/*
 * Author: dizhong zhu
 * Date: 05/04/2023
 */

import React, { useEffect, useState } from 'react'
import logo from '../../../assets/png/logo.png'
import { FormInput } from '../../../widgets/FormInput'
import { NavLink } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'
import { DASHBOARD_BASE, SIGN_IN } from '../../../common/routePaths'
import { Button, Card, Container, Form } from 'react-bootstrap'
import { DisplayAlert, DisplaySuccess } from '../../../widgets/DisplayAlert'
import { Api_ResetPassword } from '../../../apis/accounts'

function ResetPassword() {
	const [account, setAccount] = useState({ email: '', password1: '', password2: '' })
	const navigate = useNavigate()
	const location = useLocation()
	const [token, setToken] = useState('')

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search)
		const token = searchParams.get('token')
		setToken(token)
	}, [location])

	const handleResetPassword = async (event) => {
		event.preventDefault()
		// check the passwords are the same
		if (account.password1 !== account.password2) {
			DisplayAlert('Passwords are not match')
			return
		}

		try {
			const success = await Api_ResetPassword(token, account.email, account.password1)
			if (success) {
				DisplaySuccess('Reset password successfully')
				navigate(`${SIGN_IN}`)
			}
		} catch (error) {
			DisplayAlert(error.message)
		}
	}

	const handleInputChange = ({ currentTarget: input }) => {
		const updatedAccount = { ...account, [input.name]: input.value }
		setAccount(updatedAccount)
	}

	return (
		<React.Fragment>
			<Container className='d-flex align-items-center justify-content-center' style={{ height: '100vh' }}>
				<Card className='m-3' style={{ minWidth: '800px' }}>
					<Card.Header className='text-center'>
						<img src={logo} alt='Logo' width={'50%'} />
					</Card.Header>
					<Card.Body>
						<h4>Reset your password</h4>
						<Form onSubmit={handleResetPassword}>
							<FormInput
								name={'email'}
								label={'Email'}
								type={'email'}
								placeholder={'Enter email'}
								value={account.email}
								onChange={handleInputChange}
							/>
							<FormInput
								name={'password1'}
								label={'New password'}
								type={'password'}
								placeholder={'new password'}
								value={account.password1}
								onChange={handleInputChange}
							/>
							<FormInput
								name={'password2'}
								label={'Confirm password'}
								type={'password'}
								placeholder={'confirm password'}
								value={account.password2}
								onChange={handleInputChange}
							/>
							<div className='text-center'>
								<Button type='submit'>Confirm</Button>
							</div>
						</Form>
						<p className='text-center'>
							<Button variant='link'>
								<NavLink className={'nav-item nav-link'} to={SIGN_IN}>
									Back to sign in
								</NavLink>
							</Button>
						</p>
					</Card.Body>
				</Card>
			</Container>
		</React.Fragment>
	)
}

export { ResetPassword }
