import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getTenantRequest, getTenantsRequest } from 'services/tenant'
import { RootState } from 'store'

export const initialState: TenantState = {
	tenants: [],
	activeTenant: null,
	isLoadingTenants: true,
	tenantsError: [],
}

export const getTenants = createAsyncThunk<Tenant[]>('tenant/list', async (_, { dispatch }) => {
	dispatch(isLoadingTenants(true))

	return getTenantsRequest()
		.then((res: ListTenantResponse) => {
			dispatch(setTenants([]))
			if (res.data) {
				dispatch(setTenants(res.data))
			}
			return res.data
		})
		.catch((err: any) => {
			dispatch(setTenantsError([err.response.data.message]))
		})
		.finally(() => dispatch(isLoadingTenants(false))) as Promise<Tenant[]>
})

export const getTenant = createAsyncThunk<Tenant, string>('tenant/view', async (domain: string, { dispatch }) => {
	dispatch(isLoadingTenants(true))

	return getTenantRequest(domain)
		.then((res: ViewTenantResponse) => {
			dispatch(setActiveTenant(res.data))
			return res.data
		})
		.catch((err: any) => {
			dispatch(setTenantsError([err.response.data.message]))
		})
		.finally(() => dispatch(isLoadingTenants(false))) as Promise<Tenant>
})

export const tenantSlice = createSlice({
	name: 'tenant',
	initialState,
	reducers: {
		setTenants: (state, { payload }: PayloadAction<Tenant[]>) => {
			state.tenants = payload
		},
		setActiveTenant: (state, { payload }: PayloadAction<Tenant>) => {
			state.activeTenant = payload
		},
		setTenantsError: (state, { payload }: PayloadAction<any>) => {
			state.tenantsError = payload
		},

		isLoadingTenants: (state, { payload }: PayloadAction<boolean>) => {
			state.isLoadingTenants = payload
		},
	},
})

export const { setTenants, setActiveTenant, isLoadingTenants, setTenantsError } = tenantSlice.actions

export const tenantSelector = (state: RootState) => state.tenant

export default tenantSlice.reducer
