/*
 * Author: dizhong zhu
 * Date: 11/05/2023
 */

import path from "path-browserify";

function dataURLtoFileBlob(dataurl) {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], {type: mime});
}

const fetchImage = async (imageUrl) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const reader = new FileReader();
            // Extract file name from the URL
            const urlPath = new URL(imageUrl).pathname;
            const fileName = urlPath.substring(urlPath.lastIndexOf('/') + 1);
            const fileExtension = path.extname(fileName);

            reader.onloadend = () => {
                resolve({dataUrl: reader.result, type: fileExtension});
            };

            reader.readAsDataURL(blob);
        } catch (error) {
            console.error('Error fetching image:', error);
            reject(error);
        }
    });
}

export {dataURLtoFileBlob, fetchImage}
